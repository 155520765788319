import React from "react"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import content from "../../content/hero.yaml"

export default () => (
  <section id="hero">
    <div className="row">
      <div className="twelve columns">
        <div className="hero-text">
          <h1 className="responsive-headline">{content.headline}</h1>
          <p className="subheadline">Půjčovna karavanu v Praze</p>
          <p className="text-left">{content.body}</p>
        </div>

        <div className="hero-image">
          <img src={content.image} alt="" className="animated fadeInUpBig" />
        </div>
      </div>
    </div>
  </section>
)
