import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "./navmenu"

export default () => (
  <header>
    <div className="logo">
      <AnchorLink to="/#top">
        <img alt="" src={useSiteMetadata().logo} />
      </AnchorLink>
    </div>

    <NavMenu />
  </header>
)
