import React from "react"
import content from "../../content/pricing.yaml"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCaravan } from "@fortawesome/free-solid-svg-icons/faCaravan"
import { faCampground } from "@fortawesome/free-solid-svg-icons/faCampground"
import { faMountain } from "@fortawesome/free-solid-svg-icons/faMountain"

export default () => {
  library.add([faCaravan, faCampground, faMountain])

  return (
    <section id="pricing">
      <div className="row section-head">
        <h1>{content.title}</h1>
      </div>

      <div className="row">
        <div className="pricing-tables bgrid-thirds s-bgrid-halves">
          {content.plans.map((plan, index) => (
            <div className="column">
              <div className="price-block">
                <h3 className="plan-title">
                  <i className="fa">
                    <FontAwesomeIcon icon={plan.fa} />
                  </i>
                  {plan.title}
                </h3>
                <p className="plan-price">
                  {plan.price}
                  <span>{plan.per}</span>
                </p>

                <ul className="features">
                  {plan.features.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="pricing-tables bgrid-halves s-bgrid-halves">
          <div className="column">
            <div className="price-block">
              <h3 className="plan-title">Pronájem na 1-6 dní:</h3>
              <ul className="circle text-left">
                <li>
                  Servisní poplatek 1500 Kč - úklid, 1x PB lahev, chemie do WC,
                  toaletní papír.
                </li>
                <li>
                  Počet ujetých km omezen na 350 km/den, za další km účtujeme 6
                  Kč.
                </li>
              </ul>
            </div>
          </div>

          <div className="column">
            <div className="price-block">
              <h3 className="plan-title">Pronájem na 7 a více dní:</h3>
              <ul className="circle text-left">
                <li>Bez servisního poplatku.</li>
                <li>Neomezený počet km</li>
                <li>
                  Standardně je ve voze 1PB lahev plnitelná. Za využití druhé
                  lahve účtujeme 350 Kč
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="pricing-tables e">
          <div className="column full-width">
            <div className="price-block">
              <h3 className="plan-title">Co je v ceně v obou případech:</h3>
              <ul className="circle text-left">
                <li>
                  Možnost slevy při pronájmu nad 10 dní (5%) a nad 20 dní (10%).
                  Od zimní sezóny 2021-2022 bude auto připraveno na zimní
                  lyžařskou sezónu/zimní obutí, řetězy, 2PB lahve a Duo ventil.{" "}
                  <i>Nejsme plátci DPH</i>
                </li>
                <li>Bezplatné parkování Vašeho vozu po celou dobu pronájmu.</li>
                <li>
                  Veškerá výbava vozu zdarma (propojovací kabely, hadice,
                  koberec pod markýzu, dálniční známka ČR a Rakousko, rezervní
                  pneu, parkovací kamera)
                </li>
                <li>Plynový gril a zahradní nábytek</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="pricing-tables">
          <div className="column full-width">
            <div className="price-block">
              <h3 className="plan-title">Co naopak není zahrnuto v ceně:</h3>
              <ul className="circle text-left">
                <li>Pivní chlazení Lindr 20/K - 200Kč / den</li>
                <li>Přistavení vozu mimo provozovnu účtujeme 16,- Kč / 1km</li>
                <li>
                  Domácí mazlíček 800,-Kč/jednorázový poplatek (Pes pouze po
                  dohodě s pronajímatelem)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
