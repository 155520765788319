import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import content from "../../content/features.yaml"

export default () => (
  <section id="features">
    {content.map((value, index) => {
      let pos, imagepos
      if (index % 2 === 0) {
        pos = "right"
        imagepos = "left"
      } else {
        pos = "left"
        imagepos = "right"
      }

      return (
        <div className={"row feature " + value.name}>
          <div className={"six columns " + pos}>
            <h3>{value.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: value.body }} />
          </div>

          <ScrollAnimation
            animateIn="pulse"
            animateOnce={true}
            initiallyVisible={true}
            className={"six columns feature-media " + imagepos}
          >
            <img src={value.image} alt="" />
          </ScrollAnimation>
        </div>
      )
    })}
  </section>
)
