import React from "react"
import { Helmet } from "react-helmet"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import Header from "./header"
import Footer from "./footer"

import "../styles/default.css"
import "../styles/layout.css"
import "../styles/media-queries.css"
import "../styles/animate.css"
import "../styles/prettyPhoto.css"

// import "typeface-open-sans"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

export default function Layout({ children }) {
  const { title, description, author, favicon } = useSiteMetadata()
  const openSans =
    "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600&display=swap"

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="author" content={author.name} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link rel="shortcut icon" href={"/" + favicon} type="image/png" />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href={openSans} rel="stylesheet" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-5KQLLRW2BZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-5KQLLRW2BZ');`}
        </script>
      </Helmet>

      <Header />

      <main id="top">{children}</main>

      <Footer />
    </>
  )
}
