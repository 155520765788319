import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark"

const calendarUrl =
  "https://calendar.google.com/calendar/embed?src=c_ksrqb0ijad2tkknvvm9fsbfan4%40group.calendar.google.com&ctz=Europe%2FVienna"

export default () => (
  <section id="call-to-action">
    <div className="row">
      <div className="two columns header-col">
        <h1>
          <FontAwesomeIcon icon={faBookmark} />
        </h1>
      </div>
      <div className="ten columns">
        <h2>Rezervace</h2>
        <p className="text-left">
          V kalendáři si vyberte termín, ve kterém chcete vůz zapůjčit a ověřte,
          zda termín není obsazen. Na email{" "}
          <a
            href="mailto:rezervace@chcikaravan.cz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>rezervace@chcikaravan.cz</span>
          </a>{" "}
          zašlete žádost o rezervaci. Od tohoto okamžiku Vám budeme po dobu 48
          hodin vůz bezplatně rezervovat pro zadaný termín.
        </p>
        <p className="text-left">
          Do 24 hodin Vám emailem odešleme návrh nájemní smlouvy. Tu stačí
          vyplnit a naskenovanou odeslat zpět, a to do 48 hodin od provedení
          nezávazné rezervace. Po odeslání podepsané smlouvy je nutné provést
          úhradu rezervační zálohy ve výši 50% z celkové ceny rezervace.
        </p>
        <h2>Závazná rezervace</h2>
        <p className="text-left">
          Po uhrazení zálohy ve výši 50% z celkové ceny rezervace, se Vaše
          rezervace stává závaznou. Termín v kalendáři již nebude dostupný.
          Součástí emailu bude i formulář s volitelnou výbavou, kterou pro Vás
          rádi připravíme. (nafukovací člun, elektrocentrála, měnič napětí
          12-230V)
        </p>
        <p className="text-left">
          Pokud se ale nejpozději do 30 dní před začátkem Vaší dovolené
          rozhodnete ji zrušit, rezervační poplatek Vám vrátíme.
        </p>
        <h2>Platba</h2>
        <p className="text-left">
          Zálohu ve výši 50% z celkové ceny pronájmu je nutné uhradit
          bezhotovostně nejpozději 5 dní po podpisu smlouvy o pronájmu obytného
          vozu.
        </p>
        <p className="text-left">
          Nejpozději 30 dní před začátkem Vaší dovolené je potřeba doplatit
          zbývajících 50% z ceny. Při navrácení vozu pronajímateli bude
          vystavena faktura za služby.
        </p>
        <h2>Storno poplatky</h2>
        <ul>
          <li>30 dní a více před začátkem dovolené - bez poplatku</li>
          <li>15 - 29 dní před začátkem dovolené - 30% z celkové ceny</li>
          <li>7 - 14 dní před začátkem dovolené - 50% z celkové ceny</li>
          <li>7 dní a méně před začátkem dovolené - 100% z celkové ceny</li>
        </ul>

        <h2>Kalendář</h2>
        <p>
          <iframe
            title="calendar"
            src={calendarUrl}
            width="800"
            height="600"
            frameborder="0"
            scrolling="no"
            className="calendar"
          ></iframe>
        </p>
      </div>
    </div>
  </section>
)
