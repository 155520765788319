import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"

export default () => {
  const { contacts } = useSiteMetadata()
  return (
    <footer>
      <div className="row">
        <div className="columns ">
          <div className="row">
            <FontAwesomeIcon icon={faEnvelope} />
            <h3 className="contact">Kontakt a rezervace</h3>
            <ul>
              {contacts.map((contact, index) => (
                <li className="subheadline">
                  <a href={contact.url}>{contact.text}</a>
                </li>
              ))}
              <li className="subheadline">Praha 19 - Kbely</li>
            </ul>
          </div>
        </div>

        <div id="go-top">
          <button title="Back to Top" onClick={() => scrollTo("#top")}>
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </div>
    </footer>
  )
}
