import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs"

export default () => (
  <section id="how-it-works">
    <div className="row">
      <div className="two columns header-col">
        <h1>
          <FontAwesomeIcon icon={faCogs} />
        </h1>
      </div>
      <div className="ten columns">
        <h2>Jak to funguje</h2>
        <ul>
          <li>
            Vůz se předává čistý s plně natankovanou nádrží PHM, stejně tak se i
            vrací. Ve výjimečných případech a v případě enormního znečištění
            (matrace-čalounění) účtujeme čištění specializovanou firmou.
          </li>
          <li>Plyn a vodu nájemce před vrácením nedoplňuje.</li>
          <li>
            Odpadní nádrž a nádrž z WC je nájemce povinen před vrácením vozu
            vyprázdnit.
          </li>
          <li>
            Převzetí a předání vozu zabere cca 60 minut a je třeba s tím
            počítat. Vůz je vybaven hasicím přístrojem a lékárničkou, při jejich
            použití je nájemce následně povinen zakoupit novou lékárničku či
            hasicí přístroj.
          </li>
          <li>
            Vozidlo se předává od 8 hodin prvního dne půjčení a vrací se do 16
            hodin posledního dne půjčení v pracovní dny (pondělí – pátek), pokud
            není s pronajímatelem dohodnuto jinak. Počítá se každý započatý den
            pronájmu.
          </li>
        </ul>
      </div>
    </div>
  </section>
)
